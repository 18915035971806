@import "../../variables";

.users_list{
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
}
.moreButton{
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.button{
  margin: 32px 0;
  text-decoration: none;
  background: $buttonColor;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  width: 100%;
  font-size: 16px;
  &:disabled{
    opacity: 0;
    cursor: default;
  }
}