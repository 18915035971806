@import '../../variables';

.card{
  width: 50%;
  color: $black;
  position: relative;
  &:nth-of-type(2n+1){
    .close{
      right: 5px;
    }
  }
  a{
    text-decoration: none;
  }
  .content{
    position: relative;
    height: calc(100% - 16px);
    background: $black;
    margin: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &:nth-of-type(2n){
    .content{
      margin-right: 0;
    }
  }
  &:nth-of-type(2n+1){
    .content{
      margin-left: 0;
    }
  }
  .close{
    transform: rotate(45deg);
    background: $white;
    color: $black;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 99%;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 1;
    box-shadow: 0 2px 5px  rgba(0,0,0,0.2);
  }
  .label{
    color: $white;
    background-color: $buttonColor;
    position: absolute;
    z-index: 1;
    top: 4px;
    left: 4px;
    font-size: 8px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 6px;
    border-radius: 44px;
    svg{
      margin-right: 6px;
    }
  }
  .name{
    font-weight: 700;
    font-size: 14px;
    color: $white;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .item{
    font-weight: 600;
    font-size: 10px;
    color: $white;
    display: flex;
    align-items: center;
    &:last-child{
      margin-bottom: 8px;
    }
    svg{
      width: 13px;
      min-width: 13px;
      margin-right: 8px;
    }
    path{
      stroke: $buttonColor;
    }
  }
}
.button{
  text-decoration: none;
  background: $buttonColor;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 10px;
  border-radius: 8px;
}
.confirm{
  .text{
    text-align: center;
    margin-bottom: 8px;
  }
  .buttons{
    display: flex;
    .button{
      width: 100%;
      margin-right: 5px;
      cursor: pointer;
      &+div{
        margin-right: 0;
        margin-left: 5px;
        background: $gray;
      }
    }
  }
}