@import "./variables";
.rmsc.select {
  .dropdown-container {
    box-shadow: inherit;
    border: inherit;
    border-radius: 6px;
    background-color: $light;
    font-size: 14px;
    color: $black;
    margin-bottom: 12px;

    &:focus-within {
      box-shadow: inherit !important;
      border: inherit !important;
    }
  }
  .dropdown-content {
    .panel-content {
      .search {

      }
      .options{
        .select-item{
          input {
            position: absolute;
            z-index: -1;
            opacity: 0;

            & + span {
              display: inline-flex;
              align-items: center;
              user-select: none;

              &::before {
                content: '';
                display: inline-block;
                width: 1em;
                height: 1em;
                flex-shrink: 0;
                flex-grow: 0;
                border: 1px solid #adb5bd;
                border-radius: 0.25em;
                margin-right: 0.5em;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 50% 50%;
              }
            }

            &:checked {
              & + span {
                &::before {
                  border-color: $buttonColor;
                  background-color: $buttonColor;
                  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 9'%3e%3cpath fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' d='M10.6666 1.5L4.24998 7.91667L1.33331 5'/%3e%3c/svg%3e");
                }
              }
            }
          }
          &.selected{
            background: $light;
          }
        }
      }
    }
  }
}