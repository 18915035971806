.avatar{
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
  .error_avatar + img{
    display: none;
  }
}
.not_avatar{
  width: calc( 100% - 80px );
  aspect-ratio: 1/1;
  background: #ffffff;
  padding: 40px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  svg{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.error_avatar{
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    box-sizing: border-box;
    aspect-ratio: 1/1;
    background: #FF6948;
    border-radius: 10px;
    flex-direction: column;
    text-align: center;
    padding: 0 20px;
  svg{
    margin-bottom: 12px;
  }
}
.swiper{
  height: 100%;
}
.pagination{
  --swiper-pagination-bottom: auto;
  --swiper-pagination-top: 8px;
  --swiper-pagination-bullet-horizontal-gap: 1px;
  display: flex;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  span{
    min-width: 15px;
    width: 100%;
    height: 6px;
    border-radius: 0;
  }
}
