@import "../../variables";

.pinInputContainer {
  position: relative;
  *{
    font-size: 36px!important;
    font-weight: 700!important;
    letter-spacing: 12px;
  }
}
.pinInput {
  width: 100%;
  height: 55px;
  border: none;
  background: none;
  color: $buttonColor;
  position: relative;
  text-align: center;
  padding-left: 3px;
  padding-top: 0;
  padding-bottom: 0;
  &::placeholder {
    color: transparent;
  }
}
.placeholder {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: $buttonColor;
  width: 100%;
  z-index: -1;
  text-align: center;
  white-space: nowrap;
}