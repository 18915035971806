@import '../../variables';

.login{
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  word-wrap: break-word;
}
.title{
  font-size: 22px;
  color: $white;
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 28px;
}
.form{
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  width: 100%;
  *{
    box-sizing: border-box;
  }
}
.input{
  display: flex;
  height: 55px;
  border-radius: 6px;
  position: relative;
  background: #ffffff;
  width: 100%;
  border: none;
  padding: 0 20px;
  font-size: 20px!important;
}
.button{
  background: $buttonColor;
  color: $white;
  font-family: inherit;
  padding: 10px;
  min-height: 55px;
  border: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}
.result{
  text-align: center;
  a{
    color: $white;
  }
}
.access{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
  h1{
    color: $buttonColor;
    font-size: 24px;
  }
}